<template>
  <div
    v-if="message"
    class="flex justify-center items-center py-3 px-4 mb-6 rounded-md font-semibold text-sm"
    :class="{
      'bg-green-100 text-green-600': type === 'success',
      'bg-red-100 text-red-600': type === 'error',
    }"
  >
    <ErrorIcon v-if="type === 'error'" class="w-6 h-6 mr-2 flex-shrink-0" />
    <SuccessIcon v-else-if="type === 'success'" class="w-6 h-6 mr-2 flex-shrink-0" />

    <span>{{ message }}</span>
  </div>
</template>

<script>
import ErrorIcon from "@/assets/svg/error.svg?inline";
import SuccessIcon from "@/assets/svg/success.svg?inline";

export default {
  components: { ErrorIcon, SuccessIcon },
  props: {
    message: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "error",
    },
  },
};
</script>
