<template>
  <div>
    <div v-if="!loading">
      <div class="flex justify-between items-center mb-4">
        <h1 class="title-1">My Wins</h1>
        <RouterLink to="/my-wins/grid" class="text-gray-700 text-sm underline">Grid view</RouterLink>
      </div>

      <div v-if="wins.length">
        <WinCard
          v-for="(win, index) in wins"
          :key="index"
          :win="win"
          class="mb-4 flex relative overflow-hidden items-center"
        />
      </div>

      <div v-else>
        <Notification message="You still have no wins" class="mt-8" />
        <button class="btn btn-green mb-8 w-full justify-center" @click="showSharingPopup">Add Your Win!</button>
      </div>
    </div>
    <LoadingAnimation v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WinCard from "@/components/WinCard.vue";
import Notification from "@/components/ui/Notification.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";

export default {
  components: { LoadingAnimation, Notification, WinCard },
  metaInfo: {
    title: "My Wins List | Lucky Won",
  },
  computed: {
    ...mapGetters(["wins", "loading"]),
  },
  created() {
    this.fetchWins();
  },
  methods: {
    ...mapActions(["fetchWins", "showSharingPopup"]),
    showFilter() {
      this.$store.commit("UPDATE_SHOW_FILTER_POPUP", true);
    },
  },
};
</script>
