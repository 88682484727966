<template>
  <div>
    <div class="relative flex-shrink-0 w-28 h-28">
      <img
        v-if="win.game.thumbnail"
        :src="win.game.thumbnail.downloadUrl"
        :alt="win.game.name"
        class="w-full h-full object-cover bg-gray-100"
      />
      <img
        v-else-if="win.game.lottery"
        :src="win.game.lottery.logo.downloadUrl"
        :alt="win.game.name"
        class="w-full h-full object-cover bg-gray-100"
      />
    </div>
    <div class="h-28 px-3 py-3 w-full flex flex-col border-2 border-gray-200 border-l-0">
      <div class="inline-block text-md font-medium text-gray-800 mr-1 mb-2 font-semibold leading-none">
        <span class="text-lg mr-1 leading-tight">{{ win.game.name }} </span>
        <span v-if="win.game.lotteryID" class="text-xs leading-tight"> #{{ win.game.lotteryID }}</span>
      </div>

      <div class="flex mt-1 md:mt-2 text-sm items-center">
        <span class="px-3 py-1 mr-4 text-white font-semibold bg-green-600 rounded-full">{{
          win.prize.value | formatMoney
        }}</span>
        <p>{{ win.data }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    win: {
      type: Object,
      required: true,
    },
  },
};
</script>
